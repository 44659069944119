ul {
    list-style: none;
    position: relative;
    left: -30px;
}

.draft {
    color: #707070;
    font-size: 14px;
    font-weight: 700;
}

.details {
    margin-top: 6px;
    font-size: 16px;
    font-weight: 700;
}


.dateinfo {
    font-size: 14px;
    font-weight: 700;
    color: #959595;
    text-align: left;
}


.workshopbox {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #fff;
    min-height: 51px;
    margin-top: 10px;
}
.workshopLabel{
    width: 75%;
}
.buttonBox{
    width: 25%;
    text-align: end;
    button{
        color: #fff;
    }
button{
    width: 70px;
    height: 30px;
    border: none;
    margin: 4px 5px;
    border-radius: 4px;
}
.confirm{
    background-color: #0098C8;
}
}
@media screen and (max-width:400px) {
    .workshopbox{
        padding-right: 5px;
    }
}
@media screen and (max-width:320px) {
        .buttonBox{
            width: 50%;
        }
        .workshopLabel{
            width: 50%;
        }
}