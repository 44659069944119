$theme-blue: #0098c8;
$theme-blue-hover: #017da5;
$theme-green: #80be41;
$theme-green-hover: #b1cf81;
$theme-green-border: greenyellow;
$theme-red: #ed2124;
$theme-red-hover: #f06675;
$theme-yellow: #fec10e;
$theme-yellow-hover: #ffd266;
$black: #000001;
$containerGrey: #eff0f5;
$bg-grey: #edeff5;
$light-grey: #cccccc;
$containerWhite: #ffffff;
$text-gray: #707070;
$icon-grey: #a5a5a5;
$card-header-grey: #f7f7f7;

$subheader-bg-grey: #f3f3f3;
$subheader-title1: #636363;
$subheader-title2: #909090;
$border-grey: #909090;
