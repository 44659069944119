.logoImg {
  height: 32px;
}

.navIconCancel {
  width: 16px;
  height: 16px;
  margin-right: 1.5em;
}

.navIconBack {
  width: 32px;
  height: 32px;
  margin-right: 1.5em;
}

.navDiv {
  height: 50px;
  width: 100%;
  position: fixed;  
  top: 0;
  background-color: white;
  border-bottom: 1px solid #d5d5d5;
}
