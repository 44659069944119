.layoutBorder{
    border-style: solid;
    border-color: red;
}
.internalError {
    position: fixed;
    z-index: 1000;
    top: 26px;
    left: 13px;
    border: 1px solid #E60019;
    border-radius: 8px;
    color: #E60019;
    width: 390px;
    height: 45px;
    display: flex;
    background-color: #fef5f2;
    justify-content: space-between;
    font-weight: 600;
    font-size: 14px;
    span{
        margin-top: 10px;
        margin-left: 10px;
    }
    .crossIconImg {
        width: 21px;
        height: 17px;
    margin-top: 11px;
        margin-right: 6px;
    }
  }