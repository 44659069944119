.ButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}
.OnBehalfButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border: 2px solid red;
}

.cancel{
    color: red;
    font-weight: 600;
    cursor: pointer;
  }

  .searchContainer , .searchContainerActive{
    margin-top: 120px;
    width: 95%;
    margin-left: 2.5%;
    height: 50px;
    margin-bottom: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d0cccc;
    border-radius: 5px;
    input{
        border: none;
        background-color: #fff;
        font-size: 16px;
        outline: none;
    }
}

.searchContainerActive{
    margin-bottom: 0%;
    border-radius: 5px;
}
    .searchContainerActive:focus-within{
        border-color: #c1dfff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #c1dfff;
        outline: 0 none;
    }

.searchIcon{
    img{
        position: relative;
        left:10px;
    }
}

.searchInput{
    width: 95%;
    margin:0% 5% 0% 2%;
    input{
        min-width: 98%;
    }
}


.crossIcon{
    img{
        position: relative;
        right:10px;
    }
}

.addExtraPadding {
    padding-bottom: 5em;
  }