.ButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}
.OnBehalfButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border: 2px solid red;
}

.cancel{
    color: red;
    font-weight: 600;
    cursor: pointer;
  }