@import "src/styles/colors";

.blueButton {
  background-color: $theme-blue;
  border: 2px solid $theme-blue !important;
  color: $containerWhite;
  &:hover {
    background-color: $theme-blue-hover;
  }
  &:focus,
  &:active {
    background-color: $theme-blue;
    box-shadow: none;
  }
}

.blueTransparentButton {
  background-color: $containerWhite;
  color: $theme-blue;
  //   outline: 2px solid $theme-blue !important;
  border: 2px solid $theme-blue !important;

  &:hover {
    background-color: $theme-blue;
    color: $containerWhite;
  }
  &:focus,
  &:active {
    background-color: $theme-blue;
    color: $containerWhite;
    box-shadow: none;
  }
}

.greenButton {
  background-color: $theme-green;
  border: 2px solid $theme-green-border !important;

  &:hover {
    background-color: $theme-green-hover;
  }
  &:focus,
  &:active {
    background-color: $theme-green;
    box-shadow: none;
  }
}

.yellowButton {
  background-color: $theme-yellow;
  border: 2px solid $theme-yellow !important;
  &:hover {
    background-color: $theme-yellow-hover;
  }
  &:focus,
  &:active {
    background-color: $theme-yellow;
    box-shadow: none;
  }
}

.redButton {
  background-color: $theme-red;
  border: 2px solid $theme-red !important;

  &:hover {
    background-color: $theme-red-hover;
  }
  &:focus,
  &:active {
    background-color: $theme-red;
    box-shadow: none;
  }
}

.btnDisabled {
  background-color: $light-grey;
  color: $containerWhite;
  border: 2px solid $light-grey !important;
}

.card {
  border: 1px solid blue; //rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
  border: 1px solid black;

  .cardHeader {
    background-color: $theme-yellow; //rgba(0, 0, 0, 0.03);
  }

  .cardBody {
    // background-color: red;
  }
}

.font {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto,
    "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";

  .heading {
    font-size: 1.5rem; //h4 for font size
    font-weight: 500;
  }

  .generalText {
    font-size: 1rem; //for p tags default styling
    font-weight: 400;
    line-height: 1.5;
  }

  .blackFont {
    color: $black;
  }

  .greyFont {
    color: $text-gray;
  }

  .whiteFont {
    color: $containerWhite;
  }
}

.table {
  .tableHeaderBkgCol {
    background-color: blueviolet;
  }

  .tableHeadBorder {
    border: 2px solid red; //#dee2e6;
  }

  .tableBorder {
    border: 2px solid blue; //#dee2e6;
  }
}

.container {
  background-color: $containerWhite;
 min-height: 100vh;
 padding-right: 0px !important;
 padding-left: 0px !important;
}


///////////////////////////new
.signHeader{
  display: flex;
  align-items: center;
  button{
    background: #fff;
    border: none;
  }
  img{
    height: 20px;
    width: 20px;
    margin-top: -9px;
  }
}
.centerBtn{
  text-align: center;
}
.buttonMore{
  display: flex;
  justify-content: space-between;
  width: 100%;
  max-width: 360px;
}
