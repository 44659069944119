.modal {
    background: rgba(79, 78, 78, .8) !important;
}

.signPad{
    background-color: #fff;
    border-radius: 8px;
    min-width: 350px;
    max-width: 400px;
    height: 600px;
    position: absolute;
    top: 45%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 10px;
    font-family: apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;

}
.cancelIcon{
    width: 50%;
    text-align: right;
    img{
    width: 15px;
    height: 15px;
    object-fit: contain;
    }
}
    .heading{
        width: 50%;
        text-align: left;
        font-weight: 600;
        margin-bottom:5px;
        font-size: 16px;
    }
    .subHeading{
        width: 50%;
        text-align: left;
        font-size: 15px;
        margin-bottom: 5px;
    }
    .mainText{
        font-size: 15px;
        margin-bottom: 5px;
    }
    .subText{
        font-size: 14px;
    }

.signCanvas {
    width: 100%;
    height: 100%;
    min-height: 250px;
    border: 1.7px dotted #707070;
    min-width: 250px;
}

.container {
    background-color: #fff;
    min-height: 100vh;
    padding-right: 15px !important;
    padding-left: 15px !important;
}

.modalHeight {
    min-height: 600px;
}

.modalShow {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(79, 78, 78, .8);
    user-select: none;

}


.centerBtn button:disabled {
    background: rgb(173, 171, 171);
    border-color: transparent;
    color: #000;
    font-weight: 600;
}

.centerWidth {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    justify-content: center;

    button {
        min-width: 100px;
        margin: 0px 5px;
    }
}

.halfBtn:focus {
    outline: none;
    box-shadow: none;
}
.ImageGallery{
    padding: 8px 15px;
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    img{
        height: 100px;
        width: 100px;
        object-fit: contain;
    }
}

.submitButton{
    button:disabled{
        background-color: #959393 !important;
        border-color: #959393 !important;
    }
}
.ScrollforSign{
    overflow: auto;
}
.SignPadFull{
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.476);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}

@media screen and (max-width:460px) {
    .center-width {
        display: flex;
        width: 99%;
        flex-wrap: wrap;
        justify-content: center;
    }

    .centerBtn {
        margin-right: 0px !important;
    }
}
@media screen and (max-width:400px) {
    .signPad{
       margin-top: 80px;
    }
}

.notAvailableUnit{
    margin: 35vh auto;
    width: 70%;
    height: 100px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    background-color: white;
    text-align: center;
    padding: 15px;
    .disclaimarImg{
       img{
        width: 40px;
        height: 40px;
       }
    }
    .disclaimarText{
        margin-top: 10px;
        font-size: 17px;
        color:  rgb(136, 136, 136);
        font-weight: 600;
    }
    
}
