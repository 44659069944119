.mainContainer {
  background-color: #fff;
  position: absolute;
  width: 100%; 
}
.main{
    padding: 0px 15px 15px 15px;
    min-height:550px;
    margin: 0px auto 20px auto; 
    padding-bottom: 70px;

}
