@import "../../../styles/colors.scss";
.custom-file-label {
  padding: 1rem 1rem;
  height: 50px;
  font-size: 12px;
  color: $light-grey;
  border-radius: 10px;
  content: "Add Photo";
}

.custom-file-label::after {
  visibility: visible;
  content: "Add Photo" !important;
  height: 50px;
  width: 100px;
  display: flex;
  justify-content: center;
  color: black;
  font-weight: bold;
  background-color: $subheader-bg-grey;
  padding: 1rem 1rem;
}

.thumbnail {
  margin-top: 13px;
  border: 1px solid #f3f3f3;

  height: 100px;
  width: 100px;
}
.fa {
  position: absolute;
  top: 17px;
  left: 72px;
}
.collectionThumbnail {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.ImgBox {
  position: relative;
}

.UploadError {
  margin-top: 20px;
  border: 1px solid red;
  border-radius: 8px;
  color: red;
  font-weight: 600;
  width: 275px;
  height: 29px;
  display: flex;
  background-color: #fef5f2;
  justify-content: space-between;
  span {
    margin-left: -11px;
  }
  .disclaimerImg {
    width: 17px;
    height: 17px;
    margin-top: 5px;
    margin-left: 12px;
  }
  .crossIconImg {
    width: 15px;
    height: 12px;
    margin-top: 6px;
    margin-right: 6px;
  }
}
