.apprenticesLink{
   margin-top: 20px;
    width: 100%;
}


.loadingWheel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}


//for search

.searchContainer , .searchContainerActive{

        margin: 83px  auto 0px auto;
        width: 94%;
        height: 40px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #d0cccc;
        border-radius: 5px;
    input{
        border: none;
        background-color: #fff;
        font-size: 16px;
        outline: none;
    }
}
.searchContainerActive{
    margin-bottom: 0%;
    border-radius: 5px 5px 0px 0px;
}


    .searchContainerActive:focus-within{
        border-color: #c1dfff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #c1dfff;
        outline: 0 none;
    }




.searchIcon{
    img{
        position: relative;
        left:10px;
    }
}


.crossIcon{
    img{
        position: relative;
        right:10px;
        cursor: pointer;
    }
}

.searchInput{
    width: 95%;
    margin:0px 0px 0px 10px;
    input{
        min-width: 98%;
    }
}


.activeInactiveContainer{
    margin-top: 15px;
    width: 100%;
    justify-content: center;
    display: flex;
    font-weight: 600;
    font-size: 15px;
}

.active{
    width: 150px;
    height: 30px;
    padding: 4px 45px;
    color: white;
    background-color:  #0098c8;
    cursor: pointer;
    border-radius: 8px 0px 0px 8px;
}
.notActive{
    width: 150px;
    height: 30px;
    padding: 4px 45px;
    color: #0098c8;
    background-color: white;
    border: #d0cccc solid 0.5px;
    cursor: pointer;
    border-radius: 8px 0px 0px 8px;
}

.inActive{
    width: 150px;
    height: 30px;
    padding: 4px 45px;
    color: white;
    background-color:  #0098c8;
    cursor: pointer;
    border-radius: 0px 8px 8px 0px;
}
.notInActive{
    width: 150px;
    height: 30px;
    padding: 4px 45px;
    color: #0098c8;
    background-color: white;
    border: #d0cccc solid 0.5px;
    cursor: pointer;
    border-radius: 0px 8px 8px 0px;
}