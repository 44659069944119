.stages {
    position: relative;
    display: block;
    height: 40px;
    text-align: center;
    ul > li {
        text-align: center;
        width: 35px;
        height: 33px;
        line-height: 40px;
        display: inline-block;
        background: #DDD  no-repeat top right;
        margin: -3px;
        text-indent: -10px;
    }
    ul > li.selected {
        background: #0098c8 no-repeat bottom right;
    }
    .previousStage{
        background: #0098c8 no-repeat bottom right;
    }
}