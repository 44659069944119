.profileHeader{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    height: 62px;
    background-color: #fff;
    border-bottom: 0.5px solid #999;
    margin-bottom: -20px;
    position: fixed;
    top: 0;
    z-index: 100;
    width: 100%;
    .button{
        width: 10%;
        img{
            height: 22px;
            width: 22px;
            object-fit: contain;
            margin-left: 15px;
        }
    }
    .profileHeaderLogo{
        img{
            height: 40px;
            width: 150px;
            margin-left: -10%;
        }
        width: 90%;
        text-align: center;
    }
}