.btnworklog {
  background-color: #0098C8;
  border: 2px solid #0098C8 !important;
  color: White;
  height: 48px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
}
.btnworklog:focus{
  outline: none ;
  box-shadow: none ;
  background-color: #0098C8;
}

.btnworklog:hover {
  background-color: #017da5 !important;
}

.containerworklog {

  background-color: white;
  position: sticky;
  width: 100%;
  bottom: 64px;
}

.btnContainer {
  position: relative;
  min-height: 97vh;
}
.loader{
  position: absolute;
  top : 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.containerworklog {
  position: fixed;
  width: 98.5%;
  bottom: 64px;
  height: 63px;
}
@media screen and (max-width:950px) {
  .containerworklog {
    position: fixed;
    width: 97%;
    bottom: 64px;
    height: 63px;
  }
}
@media screen and (max-width:460px) {
  .containerworklog {
    position: fixed;
    width: 100%;
    bottom: 64px;
    height: 63px;
  }
  .btnworklog {
    width: 92%;
  }
}