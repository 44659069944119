@import "src/styles/colors";

.mainContainer {
  background-color: #fff;
  position: absolute;
  width: 100%;
}

.main {
  padding: 0px 15px;
  min-height: 89%;
  margin: 0px auto -40px auto;
  padding-bottom: 70px;
  min-height: 550px;
  margin-bottom: 20px;
}