@import "../../../styles/colors.scss";

.secDiv {
  margin-top: 50px;
  background-color: $subheader-bg-grey;
  height: 50px;
  border-bottom: 1px solid white;
}

.secDivTitle1 {
  color: $subheader-title1;
  font-size: 15px;
  font-weight: bold;
}

.secDivTitle2 {
  font-size: 12px;
  color: $subheader-title2;
}
