@import "../../../styles/colors.scss";
.customInputComponent {
  padding-top: 1.5em;
  padding-left: 1em;
  padding-bottom: 1.5em;
  border-radius: 10px;
}

.customInputComponent:focus {
  border-color: #c1dfff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #c1dfff;
  outline: 0 none;
}

#otherWorkPlace {
  width: 120%;
}

.customInputComponent::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  font-size: small;
  color: $light-grey;
  writing-mode: vertical-lr !important;
  background-color: white !important;
}

.customInputComponent::-moz-placeholder {
  /* Firefox 19+ */
  font-size: small;
  background-color: white !important
}
.customInputComponent:-ms-input-placeholder {
  /* IE 10+ */
  font-size: small;
  background-color: white !important

}
.customInputComponent:-moz-placeholder {
  /* Firefox 18- */
  font-size: small;
  background-color: white !important
}

@media screen and (max-width:400px) {
 #otherWorkPlace {
  width: 100%;
}
}