.EmpApprenticesLink {
    margin: 15px auto;
    width: 94vw;
    height: 57px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius: 5px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 12px;
    .EmpApprenticesName {
        color: black;
        font-size: medium;
        font-weight: bold;
    }
}