@import "src/styles/colors";
.mainContainer {
  background-color: $containerGrey;
}

.container {
  background-color: $containerWhite;
  min-height: 90vh;
  max-width: 100%;
  padding-bottom: 4em;
}

.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}
.Knowledge{
  height: 160px;
  background-color: #fff;
  width: 100%;
}
.contactLabel{
  font-size: 18px;
  border-radius: 3px;
  padding: 10px 10px;
  font-weight: 700;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  img{
      height: 17px;
      width: 34px;
      object-fit: contain;
  }
}