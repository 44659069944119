@import "../../../styles/colors.scss";
.radio-item {
  position: relative;
  //   padding: 5px 0px;
  //   margin-left: 5px;
  //   margin: 8px 0 0;
}

.radio-item input[type="radio"] {
  display: none;
}

.radio-item label {
  display: flex;
  //   flex-direction: column;
  align-items: center;

  margin-bottom: 1em;
  //   text-indent: -1em;
  //   padding-left: 1rem;
  //   min-height: 70px;
  width: 100%;
  //   color: #7d7d7d;
  color: $subheader-title2;
  font-weight: 600;
  //   border: 1px solid black;
  //   margin-top: -5px;
}
.clickable{
  cursor: pointer;
}

// .radio-item label:before {
//   content: " ";
//   display: inline-block;
//   position: relative;
//   //   top: 4px;
//   //   padding-bottom: 2px;
//   margin: 0 12px 0px 0;
//   min-width: 20px !important;
//   min-height: 20px !important;
//   border-radius: 50% !important;
//   border: 1px solid #7d7d7d;
//   background-color: transparent;
// }

.radio-item input[type="radio"]:checked + label:after {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  position: absolute;
  //   top: 5.5px;
  left: 3.5px;
  content: " ";
  display: inline-block;
  background: $theme-blue;
}
.labelWidth{
  width: 20px !important;
  margin-right: 9px;
  margin-top: 1px;
  cursor: pointer;
}
.labelWidth:before {
  content: " ";
  display: inline-block;
  position: relative;
  //   top: 4px;
  //   padding-bottom: 2px;
  margin: 0 12px 0px 0;
  min-width: 20px !important;
  min-height: 20px !important;
  border-radius: 50% !important;
  border: 1px solid #7d7d7d;
  background-color: transparent;
}
.labelInput:before{
  content: " ";
  display: inline-block;
  position: relative;
  //   top: 4px;
  //   padding-bottom: 2px;
  margin: 0 12px 0px 0;
  min-width: 20px !important;
  min-height: 20px !important;
  border-radius: 50% !important;
  border: 1px solid #7d7d7d;
  background-color: transparent;
}