
   
   .workshopsCompleted {
     padding: 7px 12px;
    text-align: left;
     box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
     background: #fff;
     width: 100%;
     border-radius: 5px;
     border: 1px solid #fff;
     min-height: 51px;
     margin-top: 15px; 
   
   }
   .details {
     font-size: 16px;
     font-weight: 700;
   }
   
   .workshopCompletedLabel{
     display:  flex;
     width: 100%;
     justify-content: space-between;
   }

   .loadingWheel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
   
   
   
   .statusField {
    width: 100%;
    margin: 20px 0px 10px 0px;
   }
   .statusBar {
     width: 100%;
     height: 10px;
     border-radius: 10px;
     background-color:  #0098c8;
     position: absolute;
     top: 0;
     left: 0;
     z-index: 10;
   }
   .activeStatus {
     width: 100%;
     height: 12px;
     border: 1px solid #0098c8;
     border-radius: 10px;
     // margin-right: 2px;
     position: relative;
     overflow: hidden;
   }
   
   .totalWorkshops{
     color: #0098c8;
   }

   .ProgressReport {
    .ProgressReportHeading {
        text-align: center;
        font-size: larger;
        font-weight: 800;

    }
}