.workshopbox {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #fff;
    min-height: 51px;
    margin-top: 10px;
    padding-top:10px;
    margin-bottom: 15px;
    ul {
        list-style: none;
        position: relative;
        left: -30px;
    }
}

.workshopLabel{
    width: 60%;
}
.details {
    font-size: 16px;
    font-weight: 700;
}
.draft {
    color: #707070;
    font-size: 14px;
    font-weight: 700;
}
.dateinfo {
    font-size: 14px;
    font-weight: 700;
    color: #707070;
    text-align: left;
    margin-top:2px;
}


.contactLabel{
    font-size: 18px;
    border-radius: 3px;
    padding: 10px 10px;
    font-weight: 700;
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    img{
        height: 17px;
        width: 34px;
        object-fit: contain;
    }
}

.btnworklog {
    background-color: #0098C8;
    border: 2px solid #0098C8 !important;
    color: White;
    height: 48px;
    width: 100%;
    font-size: 16px;
    border-radius: 10px;
  }
  .btnworklog:focus{
    outline: none ;
    box-shadow: none ;
    background-color: #0098C8;
  }
  
  .btnworklog:hover {
    background-color: #017da5 !important;
  }
  .btnworklog:disabled{
    background-color: #707070 !important;
    border-color: #707070 !important;
  }
  
@media screen and (max-width:400px) {
    .workshopbox{
        padding: 10px 0px 0px 0px;
    }
    .buttonBox{       
    button{
        width: 112px;
    }
}
}

.myProfileBox{
    height: 130px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    color: #333;
    font-weight: 600;
    margin-left: 4px;
}

.phoneIcon {
    margin-top: 15px;
    img{
        margin-right: 20px;
    }
}

.editIcon, .passwordKeyIcon{
    img{
        margin-top: 20px;
        height: 35px;
        width: 35px;
    }
    
}
.userName{
    margin-top: 10px;
}
.companyName{
    font-size: 18px;
    color: #333;
    font-weight: 600;
    margin: 12px 0px 0px 3px;
}

.SupervisorLabel{
    border-top: 2px solid #F3F3F3;;
    border-bottom: 2px solid #F3F3F3;;
     padding: 20px 10px;
    margin: 0px -13px 0px -6px;
    min-height: 75px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 18px;
    color: #333;
    font-weight: 600;
    
    img{
        height: 35px;
        width: 35px;
    }
    
}

.supervisorSection{
    min-height: 90px;
}

   .loadingWheel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}