.mainDiv {
  margin: 0;
  box-sizing: border-box;
  height: 100vh;
  width: 100%;
  position: relative;
  font-family: sans-serif;
  color: #999;
  /* background: url(/static/media/background.16aac139.png); */
  height: 100vh;
  background-size: cover;
  width: auto;
  background-position: center;
}

.deniedBox {
  height: 50vh;
  width: 50%;
  max-width: 650px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  padding: 8px;
}

.deniedBox h1 {
  font-weight: 500;
  font-size: 36px;
  color: #999;
}

.ImgDenied {
  width: 40%;
  padding: 0px 20px;
}

.ImgDenied img {
  width: 214px;
}

.acessDenied button {
  background-color: #017da5;
  color: #fff;
  width: 100px;
  height: 50px;
  border: 1px solid transparent;
  border-radius: 5px;
  font-size: 18px;
  width: 60%;
  max-width: 150px;
}

@media screen and (max-width:1220px) {
  .container1 {
    width: 960px;
  }

  .deniedBox {
    width: 65%;
    height: 38vh;
  }

  .ImgDenied img {
    width: 190px;
  }

  .ImgDenied {
    padding: 0px 30px;
  }
}

@media screen and (max-width:980px) {
  .container1 {
    width: 760px;
  }

  .deniedBox {
    height: 43vh;
    width: 75%;
  }

  .ImgDenied {
    padding: 0px 30px;
  }

  .ImgDenied img {
    width: 180px;
  }

  .deniedBox h1 {
    font-size: 32px;
  }
}

@media screen and (max-width:767px) {
  .container1 {
    width: 100%;
  }

  .deniedBox {
    height: 43vh;
    width: 75%;
  }

  .ImgDenied {
    width: 63%;
    padding: 0px 30px;
  }

  .ImgDenied img {
    width: 150px;
  }

}

@media screen and (max-width:460px) {
  .deniedBox {
    height: 43vh;
    width: 95%;
  }

  .deniedBox h1 {
    font-size: 26px;
  }

  .ImgDenied {
    width: 60%;
    padding: 0px;
  }

  .ImgDenied img {
    width: 130px;
  }

  .acessDenied button {
    height: 39px;
    width: 80%;
    font-size: 16px;
  }
}