.RejectPad{
    background-color: #fff;
    border-radius: 8px;
    min-width: 350px;
    max-width: 400px;
    height: 375px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
    padding: 15px;

    .heading{
        width: 100%;
        font-weight: 600;
    }
    .textArea{
        textarea{
        margin: 20px 0px;
        padding: 5px;
        width: 100%;
        border: none;
        border-radius: 8px;
        border: 2px solid #999;
        height: 220px;
        }
        textarea:focus{
            outline: none !important;
            border:2px solid #17a2b8;
            box-shadow: 0 0 10px #719ECE;
        }
    }
}
.RejectPadFull{
    width: 100vw;
    height: 100vh;
    background: rgba(255, 255, 255, 0.476);
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
}