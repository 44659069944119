.profileFooter{
    margin-top: auto;
    display: flex;
    position: fixed;
    background-color: #FFF;
    bottom: 0;
    z-index: 10;
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    justify-content: center;
    width: 100%;
    height: 70px;
    align-items: center;
    border-top: 0.5px solid #999;
    padding: 0px 15px;
    .resetPassword,
    .resetPindiv{
        width: 33.3%;
    }
    .active{
        color: #0098cB;
        font-weight: 600;
        width: 33.3%;
    }
    .inActive{
        color: #444;
        font-weight: 500;
        width: 33.3%;
    }
}


.onBehalfFooter{
    margin-top: auto;
    display: flex;
    position: fixed;
    background-color: #FFF;
    bottom: 0;
    z-index: 10;
    text-align: center;
    line-height: 16px;
    font-size: 14px;
    justify-content: center;
    width: 100%;
    height: 70px;
    align-items: center;
    border-top: 0.5px solid #999;
    padding: 0px 15px;
    border: 2px solid red ;
    .resetPassword,
    .resetPindiv{
        width: 33.3%;
    }
    .active{
        color: #0098cB;
        font-weight: 600;
        width: 33.3%;
    }
    .inActive{
        color: #444;
        font-weight: 500;
        width: 33.3%;
    }
}
.IconColor{
    i{
    color: #0098cB;
    font-size: larger;
    }
}

.footerButton{
cursor: pointer;
}
.saveButton{
    i{
        font-size: 20px;
        color: #0098C8;
    }
    div{
        margin-top: -11px;
        margin-bottom: 5px;
    }
}
.savedisableButton{
    i{
        font-size: 20px;
        font-weight: 500;
        color:  #999;
    }
    div{
        margin-top: -11px;
        margin-bottom: 5px;
    }
}


.onbehalfLogOut{
    color: red;
    font-weight: 600;
    cursor: pointer;
}