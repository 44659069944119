.employHeader {
    display: flex;
    position: fixed;
    width: 100%;
    background: #fff;
    z-index: 200;
    align-items: center;
    top: 0;
    height: 70px;
    left: 0;
    justify-content: space-between;
    margin-bottom: 10px;
    border-bottom: 1px solid #E1E1E1;
}

.logoImg {
    margin-left: auto;
    margin-right: auto;
    img {
        width: 150px;
        height: 40px;
    }
}
.logout{
    margin: 15px;
}
.contactLabel{
    padding: 10px;
    img{
        height: 17px;
        width: 34px;
        object-fit: contain;
    }
  }