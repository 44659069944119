@import "src/styles/colors";

.mainContainer {
  background-color: $containerGrey;
  margin-bottom: 80px;
  overflow: hidden;
}

.container {
  background-color: $containerWhite;
  min-height: 94vh;
  max-height: 100%;
  position: relative;
  padding-bottom: 170px;
  max-width: 100%;
  margin-bottom: 67px;
}

.boxShadow {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.Knowledge {
  height: 160px;
  background-color: #fff;
  width: 100%;
}