.viewWorklog {
    text-align: center;
    margin-top: 10px;
    height: 75px;

    .viewWorklogBtn {
        width: 90vw;
        height: 45px;
        background-color: #0098c8;
        border-radius: 6px;
        border: none;
        color: white;
    }
}

.loadingWheel {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.boxTopmargin {
    margin-top: 80px;
}

.UnderLine {
    border-top: 0.5px solid rgb(211, 211, 211);
    width: 90%;
    height: 5px;
    margin: auto;
    margin-top: 10px;

}

.allSetLabel {
    font-size: 20px;
    font-weight: 600;
    color: #555;
}

.studentTrackingAndStage {
    margin-top: 50px;
    padding: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;

    .ontrack {
        background-color: white;
        border: none;
        color: #80BE41;
        width: 120px;
        height: 35px;
        font-weight: bold;
        margin-top: 1px;
    }

    .stage {
        margin-left: auto;
        margin-right: 0;
        font-size: medium;
        font-weight: 600;
        display: flex;
        overflow: hidden;
    }

    .stagetext {
        padding-top: 1px;
    }

    .pagination {
        margin-left: 10px;
        margin-right: -25px;
    }

}

.keyTable {
    margin: -90px auto 120px auto;
    height: auto;

    table {
        border: 2px solid #CCCCCC;
        margin-left: auto;
        margin-right: auto;
        border-collapse: collapse;
        width: 80vw;
        height: 130px;

        th {
            padding-left: 26px;
            height: 28px;
            text-align: center;
            background-color: #0098c8;
            color: white;
            border: 1px solid #0098c8;
            font-size: 16px;
        }
        .row{
            height: 20px;
        }

        .tableData1 {
            width: 21%;
            color: black;
            border: 2px solid #CCCCCC;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            
        }

        .tableData2 {
            width: 51%;
            color: black;
            border: 2px solid #CCCCCC;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
        }

    }

}


.workshopsCompleted {
    padding: 7px 12px;
   text-align: left;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #fff;
    min-height: 51px;
    margin-top: 5px;
  
  }
  .details {
    font-size: 16px;
    font-weight: 700;
  }
  
  .workshopCompletedLabel{
    display:  flex;
    width: 100%;
    justify-content: space-between;
  }
  

  
.statusField {
    width: 100%;
    margin: 20px 0px 10px 0px;
   }
   .statusBar {
     width: 100%;
     height: 10px;
     border-radius: 10px;
     background-color:  #0098c8;
     position: absolute;
     top: 0;
     left: 0;
     z-index: 10;
   }
   .activeStatus {
     width: 100%;
     height: 12px;
     border: 1px solid #0098c8;
     border-radius: 10px;
     // margin-right: 2px;
     position: relative;
     overflow: hidden;
   }
   
   .totalWorkshops{
     color: #0098c8;
   }

   .overdueTheoryAssessmentsCountContainer{
    display: flex;
    justify-content: space-between;
    padding: 2px;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    border: 1px solid #ed202350;
    border-left: 5px solid #ED2024;
    border-radius: 5px;
    margin: 10px 0px;
    font-size: 15px;
    font-weight: 600;
    height: 35px;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
}

.overdueTheoryAssessmentsCountText{
    color: #ED2024;
   }