.keyTable {
    margin: -90px auto 20px auto;
    height: auto;

    table {
        border: 2px solid #CCCCCC;
        margin-left: auto;
        margin-right: auto;
        border-collapse: collapse;
        width: 80vw;
        height: 130px;
        margin-bottom: 80px;

        th {
            padding-left: 26px;
            height: 28px;
            text-align: center;
            background-color: #0098c8;
            color: white;
            border: 1px solid #0098c8;
            font-size: 16px;
        }
        .row{
            height: 20px;
        }

        .tableData1 {
            width: 21%;
            color: black;
            border: 2px solid #CCCCCC;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
            
        }

        .tableData2 {
            width: 51%;
            color: black;
            border: 2px solid #CCCCCC;
            text-align: center;
            font-size: 14px;
            font-weight: bold;
        }

    }

}
