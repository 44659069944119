.errorHandlePageContainer {
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    .errorPageBox {
        position: fixed;
        top: 25%;
        margin: auto;

        .errorImg {
            text-align: center;
            margin-bottom: 10px;

            .errorHandleIcon {
                width: 100px;
            }
        }

        .errorMsgBox {
            width: 500px;
            text-align: center;
            margin-bottom: 30px;

            .errorMsg1 {
                display: inline-block;
                color: #E60019;
                font-size: 18px;
                font-weight: 600;
            }

            .errorMsg2 {
                display: inline-block;
                font-size: 15px;
                font-weight: 600;

            }
        }

        .btn {
            text-align: center;

            .reloadBtn {
                width: 110px;
                height: 60px;
                border-radius: 8px;
                background-color: #017da5;
                color: white;
                border: 1px solid #017da5;
            }
        }
    }

}