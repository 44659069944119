.show-label {
    display: inline-block;
    font-size: 13px;
    font-weight: 700;
    padding-left: 10px;
}

.hide-label {
    display: none;
}

.inputFieldtop {
    width: 92%;
    min-height: 35px;
    margin-top: -5px;
}

.inputField {
    width: 92%;
    min-height: 35px;
    padding-top: 1px;
}

.inputFieldtop label {
    padding: 0px 0px 0px 10px !important;
    margin: 0px !important;
}

.inputFieldtop input {
    padding: 0px 0px 0px 10px !important;
    height: 20px;
    color: #333 !important;
    font-weight: 600 !important;
}

.showIcon {
    display: inline-block;
}

.hideIcon {
    display: none;
}

.mb-2 {
    color: #333;
    font-weight: 700;
    position: relative;
    flex-wrap: nowrap !important;
}

.borderActive:focus-within {
    border: 2px solid #0098c8 !important;
}

.borderActive:focus-within .icon {
    color: #0098c8 !important;
}

.icon1 {
    margin-top: 11px;
    color: #999;
}

.IconDialpad img {
    margin-top: 10px;
    height: 20px;
    width: 20px;
    object-fit: contain;
}

.inactive {
    display: none;
    position: absolute;
    left: 10px;
}

.borderActive:focus-within .inactive {
    display: inline-block;
}

.iconActive {
    color: #fff;
}

.crossActive {
    color: #fff !important;
}

.borderActive:focus-within .iconActive1 {
    color: #0098c8 !important;
}
.borderActive:focus-within .iconActive2 {
    color: #999 !important;
}

.borderActive:focus-within .crossActive {
    color: #999 !important;
}

.eyeActive input[type=number] {
    -moz-appearance: textfield;
}

.eyeActive input[type=number] {
    -webkit-text-security: disc;
}

.inputFieldtop input[type=password]::-ms-reveal,
input[type=password]::-ms-clear {
    display: none;
}

.text-danger {
    font-size: 16px;
    color: #dc3545 !important;
}

@media screen and (max-width:460px) {
    .inputFieldtop {
        width: 87%;
    }

    .inputField {
        width: 87%;
    }

    .text-danger {
        font-weight: 600 !important;
        font-size: 15px;
    }
}