.mainContainer{
    background-color: #fff;
    padding: 15px;
    width: 100%;
    height: 100%;
}
.header{
    width: 100%;
    text-align: center;
    height: 50px;
    display: flex;
    align-items: center;
    h3{
        width: 85%;
        margin: 0;
        text-align: center;
    }
    img{
        width: 15%;
        height: 25px;
        width: 25px;
        object-fit: contain;
        cursor: pointer;
    }
}
.noRowBorder td {
    border-top: none;
}

ul {
    list-style: none;
    position: relative;
    left: -30px;
}

.draft {
    color: #0098C8;
    font-size: 14px;
    font-weight: 700;
}

.submitted {
    color: #80BE41;
    font-size: 14px;
    font-weight: 700;

}


.right {
    height: 32px;
    width: 32px;
}

.details {
    margin-top: 6px;
    font-size: 16px;
    font-weight: 700;
}
.datelabel{
    margin-top: 6px;
    font-size: 16px;
    font-weight: 700;
}

.dateinfo {
    font-size: 14px;
    font-weight: 700;
    color: #959595;
    text-align: left;
}

.line {
    padding: 0px !important;
    margin-left: -25px !important;
    margin-right: -45px !important;

}
.mainSubmission{
    margin-bottom: 100px;
    margin-top: 135px;
    padding: 0px 15px;
    padding-bottom: 86px;
}

.workshopbox {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #fff;
    min-height: 90px;
    margin-top: 10px;
}
.workshopLabel{
    width: 70%;
}
.workshopApprove{
    width: 30%;
    text-align: right;
    ul{
        left: -25px;
    }
    li{
        font-weight: 600;
        font-size: 14px;
    }
}
.buttonBox{
    width: 25%;
    text-align: end;
button{
    width: 75px;
    height: 30px;
    border: none;
    margin: 4px 5px;
    border-radius: 4px;
}
}
.linkColor{
    text-decoration: none;
}