.employerDashboard{
    min-height: 78vh;
    max-height: 100%;
    background-color: #fff;
    position: relative;
    padding-bottom: 213px;
}
.loadingWheel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}
.labelBox{
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 15px auto;
    font-weight: 600;
    font-size: 16px;
    padding-top: 0px;
    max-width: 375px;
}
.UnderLine{
    border-top: 0.5px solid rgb(211, 211, 211);
    width: 90%;
    height: 5px;
    margin: auto;
    margin-top: 10px;

}

.labelUpcomingactive,
.labelPreviousactive{
    color: #0098C8;
    border-bottom: 2px solid #0098C8;
    padding-bottom: 5px;
}
.labelUpcoming,
.labelPrevious{
    border-bottom: 1px solid #fff;
    padding-bottom: 5px;
}

.margintop{
    margin-top: 115px;
}
.allSetLabel{
    font-size: 18px;
    font-weight: 600;
    color: #555;
}

@media screen and (max-width:380px) {
    .labelBox{
        font-size: 15px;
        padding: 0px;
    }
}
.showWorklogsActive{
    width: 149px;
    height: 90px;
    /* UI Properties */
    background: #80BE41 0% 0% no-repeat padding-box;
    box-shadow: 0px 3px 6px #00000029;
    border: 1px solid #80BE41;
    border-radius: 8px;
    padding: 0px 11px 1px 16px;
    color: #ffffff;
}
.showWorklogsActive:first-child{
    margin-right: 20px;
}
.showWorklogsInactive{
    width: 149px;
height: 90px;
/* UI Properties */
background: #E2FFC48C 0% 0% no-repeat padding-box;
box-shadow: 0px 3px 6px #00000029;
border: 1px solid #80BE41;
border-radius: 8px;
padding: 0px 11px 1px 16px;
color: #80BE41;
}
.showWorklogsInactive:first-child{
    margin-right: 20px;
}
.worklogUnitCard{
    display: flex;
    justify-content: center;
    width: 100%;
}
.count{
    font-size: 40px;
    font-weight: 800;
}
.woklogUnitText{
    font-size: 14px;
    font-weight: 600;
    margin-top: -6px;
}

@media screen and (max-width:320px) {
    .showWorklogsActive{
        min-width: 124px;
    }
    .showWorklogsInactive{
        min-width: 124px;
    }
}