.onBehalfLoginContainer {
  position: absolute;
  top: 35%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  color: #707070;;
  height: 240px;
  min-width: 320px;
  max-width: 320px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.loginInput {
  padding: 10px;
  height: 58px;
  width: 90%;
  position: absolute;
  top: 30%;
  left: 5%;
  right: 5%;
  border-radius: 7px;
  border: 2px solid gray;
  display: flex;

  input {
    width: 70%;
    outline: none;
    border: none;
  }
}
.label {
  position: absolute;
  pointer-events: none;
  left: 62px;
  top: 0;
  transition: 0.2s ease all;
}


.loginInput:focus {
  outline: none !important;
  border: 2px solid #719ece;
}

.loginButton {
  color: white;
  height: 20%;
  width: 60%;
  border: 10px transparent;
  border-radius: 10px;
  position: absolute;
  top: 65%;
  left: 20%;
  right: 20%;

  background: #017da5 !important;
}

.onBehaflLoginText {
  position: absolute;
  color:#707070;
  top: 10%;
  left: 20%;
  font-weight: 600;
  font-size: 20px;
}

.mailIcon {
  width: 25px;
  height: 25px;
  border-right: 1px solid #017da5;
  padding: 0px 5px;
}

.phoneIcon {
  width: 25px;
  height: auto;
  padding: 0px 5px;
}

.icons {
  display: flex;
}

.loginInputActive{
  padding: 10px;
  height: 58px;
  width: 90%;
  position: absolute;
  top: 30%;
  left: 5%;
  right: 5%;
  border-radius: 7px;
  border: 2px solid  #017da5;
  display: flex;

  input {
    width: 70%;
    height: 40px;
    font-weight: 600;
    padding-top: 10px;
    outline: none;
    border: none;
  }
}

.loginInputError{
  padding: 10px;
  height: 58px;
  width: 90%;
  position: absolute;
  top: 30%;
  left: 5%;
  right: 5%;
  border-radius: 7px;
  border: 2px solid #dc3545 !important;;
  display: flex;

  input {
    width: 70%;
    height: 40px;
    font-weight: 600;
    padding-top: 10px;
    outline: none;
    border: none;
  }
}

.errorMessage{
  color: #dc3545;
  font-weight: 600;
  position: absolute;
  font-size: 11px;
  text-align: center;
  bottom: 94px;
  left: 15px;
}

.disableloginButton{
  color: white;
  height: 20%;
  width: 60%;
  border: 10px transparent;
  border-radius: 10px;
  position: absolute;
  top: 65%;
  left: 20%;
  right: 20%;

  background: #cccccc!important;
}