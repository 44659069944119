.employerFooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: 70px;
    background-color: #fff;
    border-top: 1px solid #888 ;
    .iconimg{
    text-align: center;   
    img{
        height: 32px;
        width: 32px;
    }
    }
    .linkColor{
        color: #888;
        text-decoration: none;
    }
}


.onBehalfFooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: 70px;
    background-color: #fff;
    border: 2px solid red ;
    .iconimg{
    text-align: center;   
    img{
        height: 32px;
        width: 32px;
    }
    }
    .linkColor{
        color: #888;
        text-decoration: none;
    }
}

.onbehalfLogOut{
    color: red;
    font-weight: 600;
}