.marginLeft{
    margin-left: -15px;
    margin-top: -6px;
}
.ButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}
.topMargin{
    height: 10px;
}
.OnBehalfButtonPadding{
    padding-top: 2px !important;
    padding-bottom: 2px !important;
    border: 2px solid red;
}

.cancel{
    color: red;
    font-weight: 600;
    cursor: pointer;
  }