@import "src/styles/colors";

.mainContainer {
    background-color: $containerGrey;
    overflow: hidden;
}

.containerEmployer {
    background-color: $containerWhite;
    min-height: 90vh;
    max-width: 100%;
    padding-bottom: 4em;
}

.boxShadow {
    box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}