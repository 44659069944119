.noRowBorder td {
  border-top: none;
}

ul {
  list-style: none;
  position: relative;
  left: -30px;
}

.draft {
  color: #0098C8;
  font-size: 14px;
  font-weight: 700;
}

.submitted {
  font-size: 14px;
  font-weight: 700;

}

.pen {
  height: 32px;
  width: 32px;
  margin-right: -5px;

}

.right {
  height: 32px;
  width: 32px;
}

.details {

  font-size: 16px;
  font-weight: 700;
  width: 55%;
}

.draftList {
  width: 40%;
  padding: 0px 8px;
  height: 25px;
  border-right: 1px solid #999;
  text-align: right;
  min-width: 90px;
}

.dateinfo {
  font-size: 14px;
  font-weight: 700;
  color: #959595;
  width: 15%;
  height: 25px;
  padding: 0px 8px;
  text-align: right;
  border-right: 1px solid #999;
  min-width: 100px;
}

.line {
  padding: 0px !important;
  margin-left: -25px !important;
  margin-right: -45px !important;

}

.tubularList {
  display: flex;
  justify-content: left;
  text-align: left;
  align-items: center;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
  background: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #fff;
  margin-left: 31px;
  min-height: 51px;
  padding-left: 15px;
}
.tubularContainer{
  padding: 0px 5px;
}

.penIcon {
  width: 30%;
  min-width: 50px;
  text-align: right;
}
.deleteImg{
  height: 19.8px;
  margin-right: 5px;
  padding-left: 6px;
  cursor: pointer;
}

@media screen and (max-width:599px) {
  .tubularList {
    padding-left: 10px;
  }
}
@media screen and (max-width:599px) {
 .tubularList{
   display: block;
 }
 
.draftList {
  width: 40%;
  padding: 0px;
  height: 25px;
  border: none;
  text-align: left;
  min-width: 90px;
  white-space: nowrap;
}
.dateinfo{
  padding: 0px;
  text-align: left;
  border: none;
}
.tubularList{
  position: relative;
}
.penIcon{
  position: absolute;
  top: 50%;
  right: -21px;
  transform: translate(-50%,-50%);
  width: 20%;
}
.deleteImg{
  margin-right: 0px;
}
}
