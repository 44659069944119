.ProgressReportTable {
    margin: 15px auto 120px auto;
    height: auto;

    table {
        color: black;
        margin-left: auto;
        margin-right: auto;
        width: 90vw;

        td {
            padding: 12px;
            border: 2px solid #CCCCCC;
            text-align: center;
            font-size: 14px;
            font-weight: 800;
        }
    }
}