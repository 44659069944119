.employerStudentHeader {
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    height: 60px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;

    .cancelIcon {
        width: 16px;
        height: 16px;
        margin-left: 4px;
        margin-top: 2px;
    }

    .studentName {
        text-align: left;
        font-size: medium;
        font-weight: 600;
        position: fixed;
        margin-left: 40px;
        top: 20px;
    }
}

.logoImg {
    text-align: center;

    img {
        width: 150px;
        height: 40px;
    }
}


.studentTrackingAndStage {
    margin-top: 5px;
    padding: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #E1E1E1;

    .ontrack {
        background-color: white;
        border: none;
        color: #80BE41;
        width: 120px;
        height: 35px;
        font-weight: bold;
        margin-top: 1px;
        margin-left: 5px;
    }

    .stage {
        margin-left: auto;
        margin-right: 0;
        font-size: medium;
        font-weight: 600;
        display: flex;
        overflow: hidden;
    }

    .stagetext {
        padding-top: 1px;
    }

    .pagination {
        margin-left: 10px;
        margin-right: -25px;
    }
}

.main {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: #fff;
    height: 65px;
    width: 100%;

}
.trainerMain {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 200;
    background-color: #fff;
    height: auto;
    width: 100%;

}