.searchContainer , .searchContainerActive{
    max-width: 500px;
    width: 100%;
    height: 50px;
    margin-bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d0cccc;
    border-radius: 5px;
    input{
        border: none;
        background-color: #fff;
        font-size: 16px;
        outline: none;
    }
}
.searchContainerActive{
    margin-bottom: 0%;
    border-radius: 5px 5px 0px 0px;
}


    .searchContainerActive:focus-within{
        border-color: #c1dfff;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #c1dfff;
        outline: 0 none;
    }




.searchIcon{
    img{
        position: relative;
        left:10px;
    }
}


.crossIcon{
    img{
        position: relative;
        right:10px;
    }
}

.searchInput{
    width: 95%;
    margin:0% 5% 0% 2%;
    input{
        min-width: 98%;
    }
}

.searchResulContainer , .searchResulContainerActive{
    max-width: 500px;
    width: 100%;
    padding: 10px;
    margin-bottom: 25px;
    border: 1px solid #d0cccc;
    border-top: 0px;
    border-radius: 0px 0px 5px 5px;
}

.addaTool, .showMoreTool{
    display: flex;
    align-items: center;
    margin-top: 10px;
    img{
        cursor: pointer;
        width: 22px;
        height: 22px;
        margin-right: 23px;
    }
    span{
        cursor: pointer;
    }

}
.toolBox{
    position: relative;
    max-height: 200px; 
    min-height: 50px;
    white-space: nowrap;
    overflow:auto 
}

.loaderIcon{
    position: absolute;
    top: 3%;
    left: 30%;
    transform: translate(-50% -50%);
    img{
        width: 45px;
        height: 45px;
    }
}