@import "../../../styles/colors.scss";

.shiftTextRightAndStyle {
  margin-left: 14px;
  color: $subheader-title2;
}

.header1 {
  font-weight: 1000;
}

.header1,
.header2 {
  font-size: 1.2em;
}

.header2 {
  color: $subheader-title2;
}
.shiftTextRightcurrent{
  color: #909090;
  margin-left: -1px;
}
.item:not(:last-child)::after { content: ',' }

.fixedBottomContainer{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ButtonPadding{
  padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}
.loader{
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 10;
}
.OnBehalfButtonPadding{
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  border: 2px solid red;
}

.cancel{
  color: red;
  font-weight: 600;
  cursor: pointer;
}