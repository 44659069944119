.worklog-container {
  padding: 0px !important;
  margin: 0px !important;
  position: relative;
}

.worklogContainer {
  padding-bottom: 80px !important;
}


.leftImg {
  width: 32px;
  height: 32px;
  cursor: pointer;
  position: relative;
  top: -15px;
  left: -20px;
}

.subarea {
  padding-right: 0px !important;
  padding-left: 0px !important;
}

.logoImg {
  padding-right: 10px;
  width: 130px;
  height: 36px;
  position: relative;
  top: -15px;
  margin-left: auto;
}

.activity {
  font-size: 16px;
  position: relative;
  left: -15px;
  top: -15px;
  font-weight: 700;
}

.nav {
  height: 40px;

  padding-bottom: 0px !important;
  margin-bottom: 0px !important;

}
.Nav{
  background-color: #fff;
  position: sticky;
  top: 0px;
  z-index: 10;
  margin-top: -16px;
  padding-top: 15px;
  width: 100% ;
}
.tubularContainer{
  padding: 0px 5px;
  min-height: 100vh;
  margin-top: 40px;
  margin-bottom: 65px;
}
.loader{
  position: absolute;
  top : 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@media screen and (max-width:460px) {
  .Nav{
    background-color: #fff;
    position: fixed;
    top: 16px;
    z-index: 10;
    margin-top: -16px;
    padding-top: 15px;
    width: 100% ;
  }
  .tubularContainer{
    margin-top: 85px;
    margin-bottom: 65px;
  }
}