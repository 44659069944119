.onlineCourseLogin{
 width: 96%;
 text-align: center;
 position: relative;
 top:10px;
 margin-bottom: 30px;
 margin-left: auto;
 margin-right: auto;
 a{
  display: block;
   color:white;
   text-decoration: none;
   background-color: #80BE41;
   width: 100%;
   height: 48px;
   border: none;
   border-radius: 8px;
 }
 p{
padding-top: 12px;
 }
}

.upPrevWorkshop{
    padding:3px;
    border-right: 1px solid black;
}

  .UnderLine{
    border-top: 0.5px solid rgb(211, 211, 211);
    width: 90%;
    height: 5px;
    margin: auto;
    margin-top: 10px;

}
.allSetLabel{
  font-size: 20px;
    font-weight: 600;
    color: #555;
}
.employerDashboard{
  min-height: 78vh;
  max-height: 100%;
  background-color: #fff;
  position: relative;
  padding-bottom: 213px;
}

.workshopsCompleted {
  padding: 7px 12px;
 text-align: left;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
  background: #fff;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #fff;
  min-height: 51px;
  margin-top: 5px;

}
.details {
  font-size: 16px;
  font-weight: 700;
}

.workshopCompletedLabel{
  display:  flex;
  width: 100%;
  justify-content: space-between;
}



.statusField {
 width: 100%;
 margin: 20px 0px 10px 0px;
}
.statusBar {
  width: 100%;
  height: 10px;
  border-radius: 10px;
  background-color:  #0098c8;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
}
.activeStatus {
  width: 100%;
  height: 12px;
  border: 1px solid #0098c8;
  border-radius: 10px;
  // margin-right: 2px;
  position: relative;
  overflow: hidden;
}

.totalWorkshops{
  color: #0098c8;
}

.overdueTheoryAssessmentsCountContainer{
  display: flex;
  justify-content: space-between;
  padding: 2px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
  border: 1px solid #ed202350;
  border-left: 5px solid #ED2024;
  border-radius: 5px;
  margin: 10px 0px;
  font-size: 15px;
  font-weight: 600;
  height: 35px;
  align-items: center;
  img{
    width: 20px;
    height: 20px;
    margin-right: 10px;
  }

}

.overdueTheoryAssessmentsCountText{
 color: #ED2024;
}