@import "../../../styles/colors.scss";
.checkbox-class {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
}

.checkbox-class input[type="checkbox"],
.form-check-input {
  appearance: none;
  -webkit-appearance: none;
  height: 22px;
  min-width: 22px;
  border: 1px solid $subheader-title2;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.checkbox-class label {
  margin-left: 20px;
  cursor: pointer;
  margin-bottom: 0px;
  color: $subheader-title2;
  font-weight: 600;
}

.checkbox-class input[type="checkbox"]:after {
  content: url("../../../assets/images/checkmark16.png");
  display: none;
  margin-top: 2px;
}

.checkbox-class input[type="checkbox"]:checked::after {
  display: block;
}

.checkbox-class label input[type="text"]:focus {
  //   background-color: aqua;

  //   border-radius: 115px;
  border-color: #c1dfff;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.075) inset, 0 0 8px #c1dfff;
  outline: 0 none;
}
.checkbox-margin{
  margin-bottom: 0px !important;
}
.checkbox-class input[type="checkbox"]:checked {
  background-color: #0098c8 !important;
  border-color: transparent;
  border-radius: 4px;
  font-size: 6px;
}