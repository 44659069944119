.overlay-modal {
    border-radius: 8px;
    height: 375px;
    min-width: 335px;
    max-width: 400px;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #fff;
    z-index: 1000;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.overlay-header {
    position: relative;
    background-color: rgba(236, 240, 241, 1);
    height: 3rem;
    display: flex;
}

.overlay-header h3 {
    padding: 0.5rem;

}

.overlay-close-icon {
    position: absolute;
    right: 12px;
    top: -6px;
}

.overlay-close {
    height: 15px;
    margin: 1.5rem 0 0 8rem;
}

.overlay-success {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 4rem;
}

.overlay-body {
    padding: 50px;
}

.overlay-content {
    margin: 19% auto;
    text-align: center;
    white-space: nowrap;
}

.overlay-footer {
    display: flex;
    justify-content: center;
    align-items: center;
}

.top_overlay {
    width: 100vw;
    height: 100vh;
    position: fixed;
    z-index: 1000;
    top: 0;
    background: rgba(255, 255, 255, 0.476);
}