.onBehalfFooter,
.employerFooter{
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    z-index: 10;
    height: 80px;
    background-color: #fff;
    border-top: 1px solid rgb(203, 203, 203) ;
    img{
        height: 32px;
        width: 32px;
        object-fit: contain;
    }
    .IconColor{
        text-align: center;
    }
}
.homeIcon,
.apprenticesIcon,
.settingIcon{
    color: #888;
    text-decoration: none;
}
.onbehalfLogOut{
    color: red;
    font-weight: 600;
    cursor: pointer;
}
.onBehalfFooter{
    border: 2px solid red ;
}