.addExtraPadding {
  padding-bottom: 5em;
  padding-top: 100px;
}
.addExtraPaddingtop{
  padding-bottom: 5em;
} 
.navIcon{
  height: 32px;
  width: 32px;
}
.headerFix{
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 10;
}
.stepOne{
  position: relative;
}
.mainContainersteps{
  position: relative;
}

.notFound{
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 20px;
  font-weight: 600;
}

.checkboxForAddaTool{
margin-bottom: 15px;
}