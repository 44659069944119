@import "src/styles/colors";

.mainContainer {
  background-color: $containerGrey;
}

.innerContainer {
  background-color: $containerWhite;
  margin-top: -20px;
  padding: 0 15px;
}