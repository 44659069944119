.apprenticesLink{
    margin-top: 90px;
    width: 100%;
    position: absolute;
    align-items: center;
}


.loadingWheel{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.EmpApprenticesLink {
    margin: 15px auto;
    width: 94vw;
    height: 57px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    border: none;
    border-radius: 5px;
    background-color: white;

    .EmpApprenticesName {
        color: black;
        font-size: medium;
        font-weight: bold;
    }
}
.allSetLabel{
    font-size: 18px;
      font-weight: 600;
      color: #555;
      margin:20px 0px 0px 20px;
  }