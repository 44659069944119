.ContactLogo{
    width: 360px;
    margin: 20px auto -20px auto;
    text-align: center;
    font-size: 14px;
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    bottom: -50px;
    z-index: 0;
    img{
        width: 227px;
        height: 70px;
        object-fit: contain;
    }
    .ConatactBox{
        display: flex;
        justify-content: space-around;
        align-items: center;
        height: auto;
        flex-wrap: wrap;
        margin-top: 10px;
        div{
            padding: 5px;
        }
        a{
            color: #111111;
            font-size: 14px;
            font-weight: 600;
        }
        img{
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin: 5px;
        }
    }
}