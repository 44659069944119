.logoImg {
    margin: 20px auto;
    text-align: center;
    img{
    width: 150px;
    height: 40px;
    }
}
.mainContainer{
    background-color: #fff;
    padding: 15px;
    width: 100%;
    height: 100%;
}