@import "../../styles/colors.scss";

.shiftTextRightAndStyle {
  margin-left: 14px;
  color: $subheader-title2;
}

.header1 {
  font-weight: 1000;
}

.header1,
.header2 {
  font-size: 1.2em;
}

.header2 {
  color: $subheader-title2;
}
.shiftTextRightcurrent{
  color: #909090;
  margin-left: -1px;
}
.item:not(:last-child)::after { content: ',' }

.fixedBottomContainer{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ButtonPadding{
  padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}

.OnBehalfFooter{   display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 0 7%;
  position: fixed;
  z-index: 10;
  bottom: 0;
  align-items: center;
  background: #fff;
  height: 80px;
  border: 2px solid red;
  text-align: center;
  button{
      border: none;
      font-weight: 600;
      color: rgb(136, 136, 136);
      background-color: #fff;
      font-weight: 400;
  }
  .approveIcon,
  .rejectIcon{
    display: grid;
    width: 122px;
    text-align: center;
    img{
      margin-left: 50px;
    }
  }
  .cancel{
    color: red;
    font-weight: 600;
    cursor: pointer;
  }
}

.footerButton{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 7%;
    position: fixed;
    z-index: 10;
    bottom: 0;
    align-items: center;
    background: #fff;
    height: 80px;
    border-top: 1px solid #cbcbcb;
    text-align: center;
    button{
        border: none;
        font-weight: 600;
        color: rgb(136, 136, 136);
        background-color: #fff;
        font-weight: 400;
    }
    .approveIcon,
    .rejectIcon{
      display: grid;
      width: 122px;
      text-align: center;
      img{
        margin-left: 50px;
      }
    }
}

