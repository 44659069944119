.profileLabel{
font-size: 16px;
font-weight: 600;
color: #333;
margin-top: 30px;
display: flex;
align-items: center;
justify-content: flex-start;
}

.profileInput{
    position: relative;
    input{
    background-color: white !important;
    border: 1px solid #999;
    height: 50px;
    padding-left: 10px;
    width: 100%;
    font-weight: 600;
    border-radius: 10px;
    }
    input:focus { 
        outline: none !important;
        border: 2px solid #719ECE;
        border-color: #719ECE;
    }
    input:disabled{
        background-color: lightgrey !important;
    }
}
.blueDot{
    width: 14px;
    height: 18px;
}
.eyeIcon{
    position: absolute;
    top: 15px;
    right: 14px;
    i{
        color: #888;
    }
}

.plaintext{
font-weight: 600;
font-size: 16px;
color: black;
}