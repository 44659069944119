@import "../../../styles/colors.scss";
.btn {
  padding: 0;
  border: none;
  border-radius: 10px;
  background-color: #fff;
  cursor: pointer;
  img{
    width: 30px;
    height: 30px;
    object-fit: contain;
}
  span{
    font-size: 16px;
    color: #999;
  }
  .textUp{
    position: relative;
    top: -3px;
    color: $black;
  }
  .textUpdark{
    color: $icon-grey;
    position: relative;
    top: -3px;
  }
}
.btnworklog {
  background-color: #0098C8;
  border: 2px solid #0098C8 !important;
  color: White;
  height: 48px;
  width: 100%;
  font-size: 16px;
  border-radius: 10px;
}

.btnworklog:hover {
  background-color: #017da5 !important;
}

.btnworklog:focus{
  outline: none;
  box-shadow: none;
  background: #0098C8 !important;
}
