.Label{
    width: 100%;
    position: relative;
    height: 50px;
    font-size: 16px;
    font-weight: 600;
    top: 70px;
    left: 0;
    background-color: #F3F3F3;
    padding: 14px 20px;
    border-top: 0.5px solid rgb(212, 210, 210);
    border-bottom: 0.5px solid rgb(212, 210, 210);
}