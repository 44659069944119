.btnsupervisor {
    background-color: #0098C8;
    border: 2px solid #0098C8 !important;
    color: White;
    height: 48px;
    width: 96%;
    font-size: 16px;
    border-radius: 10px;
    margin: 2%;
  }
  .btnsupervisor:focus{
    outline: none ;
    box-shadow: none ;
    background-color: #0098C8;
  }
  
  .btnsupervisor:hover {
    background-color: #017da5 !important;
  }
  .btnsupervisor:disabled{
    background-color: #707070 !important;
    border-color: #707070 !important;
  }
  .error{
    color: red;
  }