.noRowBorder td {
    border-top: none;
}

ul {
    list-style: none;
    position: relative;
    left: -30px;
}

.draft {
    color: #707070;
    font-size: 14px;
    font-weight: 700;
}

.submitted {
    color: #80BE41;
    font-size: 14px;
    font-weight: 700;

}


.right {
    height: 32px;
    width: 32px;
}

.details , .crossedDetails{
    font-size: 16px;
    font-weight: 700;
}
.crossedDetails{
    text-decoration: line-through;
}


.dateinfo, .crossedDateInfo {
    font-size: 14px;
    font-weight: 700;
    color: #707070;
    text-align: left;
    margin-top:2px;
}
.crossedDateInfo{
    text-decoration: line-through;
}

.line {
    padding: 0px !important;
    margin-left: -25px !important;
    margin-right: -45px !important;

}

.workshopbox {
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 8px;
    border: 1px solid #fff;
    min-height: 51px;
    margin-top: 10px;
    padding-top:10px;
}
.workshopLabel{
    width: 60%;
}
.buttonBox{
    width: 40%;
    text-align: end;
    
button{
    width: 120px;
    height: 30px;
    border: none;
    margin: 4px 9px;
    border-radius: 8px;
}
.contact{
    background-color: #0098c8;
    color: white;
    
}
.confirm{
    background-color: #80be41;
    color: white;
    
}
.submitReminder{
    background-color: #0098c8;
    color: white;
}

}
.linkColor{
    text-decoration: none;
}

.buttonBoxForBeThere{
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 0px 40px;
    align-items: center;
button{
    width: 120px;
    height: 30px;
    border: none;
    margin: 4px 5px;
    border-radius: 8px;
}
.contact{
    background-color: #0098c8;
    color: white;
}
.confirm{
    background-color: #80be41;
    color: white;
}
}
.workshopLabelbeThere{
    display:  flex;
    width: 100%;
    justify-content: space-between;
}


.beThereBox {
    padding: 7px 12px;
   text-align: left;
    box-shadow: 0 3px 10px rgba(0, 0, 0, 0.096);
    background: #fff;
    width: 100%;
    border-radius: 5px;
    border: 1px solid #fff;
    min-height: 51px;
    margin-top: 5px;
}


.dateinfobeThere {
    font-size: 15px;
    font-weight: 700;
    color: #111111;
    text-align: left;
}
.statusAttended{
    padding: 0px 10px;
    color: #80BE41;
    font-weight: 600;
    font-size: 14px;
}
.statusnotAttended{
    padding: 0px 10px;
    color: #ED2024;
    font-weight: 600;
    font-size: 14px;
}
@media screen and (max-width:400px) {
    .workshopbox{
        padding: 10px 0px 0px 0px;
    }
    .buttonBox{
        
    button{
        width: 112px;
    }
}
.buttonBoxForBeThere{
    padding: 0;
}
}
@media screen and (max-width:320px) {
        .buttonBox{
            width: 50%;
        }
        .workshopLabel{
            width: 50%;
        }
}

.assessmentOverDue , .assessmentNotOverDue{
    font-size: 12px;
    height: 26px;
    margin-left: -10px;
    margin-top: -12px;
    height: 18px;
    color: #ED2024;
    img{
        width: 40px;
        height: 40px;
    }
}
.assessmentNotOverDue{
color: #0098c8 !important;
}

.statusRescheduled{
    padding: 0px 10px;
    color: orange;
    font-weight: 600;
    font-size: 14px;
}

.statusFRescheduled{
    padding: 0px 10px;
    color: #707070;
    font-weight: 600;
    font-size: 14px;

}

