@import "../../../styles/colors.scss";

.accordianMain {
  border-bottom: 0.2px solid #EFEFEF;
  //border-top: 0.2px solid #EFEFEF !important;

}

.overduTaskName {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  color: #909090;
  font-weight: 600;

  .radiobutton {
    width: 300px;
    
  }

  .photosRequired {
    width: 100px;
    margin-bottom: 1em;
    img{
      height: 24px;
      object-fit: contain;
      width: 25px;
    }
  }

  .numberPerformed {
    width: 100px;
    margin-bottom: 1em;
    img{
      height: 24px;
      object-fit: contain;
      width: 25px;
    }
  }
 .iconphoto{
   padding: 0px 10px;
 }
}
.textGreen{
  color: rgb(54, 179, 126);
}
@media screen and (max-width:460px) {
.overduTaskName{
  width: 100%;
}
}