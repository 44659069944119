.notification{
    color:black;
    font-weight: bolder;
}
.subnotification{
    color:#707070;
    font-weight: 700;
    padding-top: 10px;
}
.resetenvelop{
    padding-top: 20px;
}
input{
    background-color: white !important;
}
.getReset{
    visibility: visible;
}
