@import "../../../styles/colors.scss";

.shiftTextRightAndStyle {
  margin-left: 14px;
  color: $subheader-title2;
}

.header1 {
  font-weight: 1000;
}

.header1,
.header2 {
  font-size: 1.2em;
}

.header2 {
  color: $subheader-title2;
}
.shiftTextRightcurrent{
  color: #909090;
  margin-left: -1px;
}
.item:not(:last-child)::after { content: ',' }

.fixedBottomContainer{
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.ButtonPadding{
  padding-top: 2px !important;
    padding-bottom: 2px !important;
    border-top: 0.5px solid rgb(233, 231, 231);
}
.footerButton{
    display: flex;
    justify-content: space-around;
    width: 100%;
    padding: 0 7%;
    position: fixed;
    z-index: 10;
    bottom: 0;
    height: 72px;
    align-items: center;
    background: #fff;
    button{
        width: 122px;
        height: 38px;
        border: none;
        border-radius: 5px;
        font-weight: 600;
    }
    .rejectButton{
        background-color: #b44040;
    }
    .approveButton{
        background-color: #548235;
    }
}