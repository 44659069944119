.mainContainer {
  background-color: #fff;
  position: absolute;
  width: 100%;
}

.main {
  padding: 0px 15px;
  min-height: 89%;
  margin: 0px auto -40px auto;
  padding-bottom: 70px;
  min-height: 550px;
  margin-bottom: 20px;

  .orBorder {
    border-bottom: 1px solid #999;
    position: relative;
    width: 80%;
    margin: 40px auto 0px auto;

    span {
      color: #0098c8;
      padding: 10px 20px;
      position: absolute;
      top: -21px;
      background: #fff;
      font-weight: 600;
      left: 38%;
    }
  }
}