
  .modalImage{
    img{
      width: 100%;
     height: auto;
    }
  }

  .buttonClose{
    img{
      width: 20px;
      height: 20px;
    }
  }