@import "src/styles/colors";

.bg-loginform {
  background: $containerWhite;
  min-width: 650px;

  .bg-grey {
    background: $bg-grey;
  }

  .login-form-container {
    /* background: #0098c8; */
    min-height: 100vh;
    margin: 0;
    position: absolute;
    /* top: 60%; */
    /* left: 50%; */
    /* margin-right: -50%; */
    padding: 0 !important;
    /* transform: translate(-50%, -50%)  */
  }

  .form-card {
    height: max-content;
    /* min-height: 80vh; */
    padding-top: 2rem;
    padding-bottom: 4rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }

  a {
    color: #909090
  }

  .form-width-inherit {
    width: inherit;
  }

  .card-header {
    height: max-content;
  }

  .logo {
    width: inherit;
    max-width: 200px;
  }

  .login-btn {
    width: 100%;
    height: 60px;
    border-radius: 8px;
    text-transform: uppercase;
  }

  .login-btn:hover {
    background: $theme-blue-hover;
    color: $containerWhite;
  }

  .halfBtn {
    background: $theme-blue;
    color: $containerWhite;
    width: 100%;
  }

  .halfBtn:hover {
    background: $theme-blue-hover;
    color: $containerWhite;
  }

  input {
    border: none;
    background-color: transparent !important;
  }

  input:active {
    box-shadow: $containerWhite;
  }

  input:focus,
  input:active,
  select:focus,
  textarea:focus,
  button:focus {
    outline: none;
  }

  .fa-user-circle-o {
    color: gray;
  }

  .input-group {
    border: 1px gray solid;
    padding: 10px;
    background-color: white !important;
  }

  .input-group {
    background: $containerWhite !important;
    border: 2px solid $light-grey;
    border-radius: 5px;
  }

  .icon {
    color: $icon-grey;
    padding-top: 12px;
  }

  .form-control {
    border: 0;
  }

  .form-control:focus,
  .form-control:valid,
  .form-control:invalid {
    box-shadow: unset;
  }

  .btn-able {
    background: #0098C8 !important;
  }

  .btn-disabled {
    background: $light-grey;
    pointer-events: none;
  }

  .btn.disabled,
  .btn:disabled {
    background: $light-grey !important;
  }

  .color-active {
    color: $theme-blue;
  }

  .bg-active {
    background: #017da5 !important;
    margin-top: 20px !important;
  }

  .border-active {
    border: 1px solid $theme-blue;
  }

  .border-error {
    border: 2px solid red;
  }

  .border-success {
    border: 2px solid green;
  }

  .text-gray {
    color: $text-gray;
    font-weight: 600;
  }

  .text-align-right {
    text-align: right;
  }

  .disabled {
    opacity: 0.5;
    cursor: not-allowed !important;
    pointer-events: none !important;
  }

  .enabled {
    opacity: 1;
    cursor: default;
  }

  .minHeight{
    min-height: 300px;
  }

  .loader {
    margin: auto;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    overflow: hidden;
  }

  .modal {
    background: rgba(79, 78, 78, 0.8);
  }

  .modal.show .modal-dialog {
    top: auto;
  }

  .modal-dialog {
    max-width: 350px;
  }
}

.checkbox-class input[type="checkbox"],
.form-check-input {
  border-radius: 4px !important;
}


@media screen and (max-width:767px) {
  .bg-loginform {
    min-width: 500px;
  }
}

@media screen and (max-width:460px) {
  .bg-loginform {
    min-width: 360px;
  }
}

.loader{
  position: absolute;
  top : 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.generateLoginLink{
  color: #909090;
  font-weight: 700;
  margin-left: 40px;
}